.Back {
  display: flex;
  flex-direction: row;
  width:100%;
  height:100%;
  position: relative;
  align-items: center;
  justify-content: space-around;
}
.back_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #28142F;
  width:4vh;
  height:4vh;
}
.Back .title {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 4vh;
  visibility: hidden;
}
.back_wrp div {
  margin-left: 3vh;
}


@media(max-width:500px) {
 .Back .title {
  visibility: visible;
 }
 .back_btn {
  position: relative;
  top: 0%;
  left: 0%;
  transform: translate(0);
 }
}