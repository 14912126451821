@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #DEDEDE;
}
.content {
  background: white;
  width:85%;
  height:85%;
  position: relative;
  border-radius:7vh;
}
.content_messages {
  width:70%;
  height: 100%;
  position: absolute;
  top:50%;
  left:60%;
  transform:translate(-50%, -50%);
  padding-top:3px;
}
.content_back {
  position: absolute;
  width:10%;
  height:15%;
  background: transparent;
  top:0;
  z-index: 15;
  right:0;
  
}
.content_info_bar {
  height:100%;
}





/* Responsivites */


@media(max-width: 1000px) {
  .content {
    width:100%;
    height:100%;
    border-radius: 0;
  }
}

@media(max-width: 900px) {
  .infoBar {
    height:100%;
    width:30%;
  }
  .content_messages {
    width:70%;
    left:65%;
  }
  .messageBox {   
    max-width:80%;
  }
  .content_back {
    height: 5%;
}
}

@media(max-width: 500px) {
  .infoBar {
    height:90%;
    position: absolute;
    top: 10%;
    width:100%;
    display:none;
  }
  .infoBar .title {
    display: none;
  }
  .yourInfo img {
    width:100%;
    height: 100%;
  }
  
  .wrp-yi {
    height: 40vh;
  }
  .wrp-yi .room {
    font-size:6vh;
    top:8vh;
  }
  .wrp-yi .name {
    font-size:7.5vh;
  }
  .into-yi {
    bottom: -4vh;
  }
  
  .activeContainer h2 {
    font-size:6vh;
  }
  .activeImage {
    width: 6vh;
  }
  
  .content_messages {
    width:100%;
    height:90%;
    left:0;
    top:10%; 
    transform: translate(0);
  }
  .content_back {
    width: 100%;
    height:10%;
    position: absolute;
    background-color: #FFF;
    border-bottom: 1px solid #DEDEDE;
  }
  .show {
    display: block;
  }
}


