.infoBar {
  background: transparent;
  height: 100%;
  width: 20%;
  border-right: 1px solid #cccccc;
  position: absolute;
  top:0;
  background-color: white;
  z-index: 55;
  border-radius:7vh 0 0vh 7vh;
}
.infoBar .title {
  color: #28142F;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-align: center;
  font-size: 3vh;
  position: relative;
  top:2vh;
  padding:1vh 0 1vh 0;
}
.yourInfo img {
  width:100%;
  height: 100%;
}




.wrp-yi {
  width:100%;
  height: 30vh;
  background-image: url("../../icons/qq.svg");
  background-size: cover;
  background-position:center;
  background-repeat: no-repeat;
  position: relative;
  top:2vh;
}
.wrp-yi .room {
  color:#E2E2E2;
  font-family: 'Poppins', sans-serif;
  font-weight: lighter;
  text-transform: capitalize;
  font-size:4vh;
  position: relative;
  top:5vh;
}
.wrp-yi .name {
  color:#FFFFFF;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  font-size:4.4vh;
  letter-spacing: .2vh;
}
.into-yi {
  position: absolute;
  bottom: 0vh;
  left: 3vh;
}

.activeContainer h2 {
  color:#959595;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size:3.5vh;
  text-transform: capitalize;
}
.activeContainer {
  width: 90%;
  overflow-x: hidden;
  margin-top:6vh;
  margin-left:2vh;
}
.overF::-webkit-scrollbar {
  width: 1vh;
}
.overF::-webkit-scrollbar-thumb {
  background: #959595; 
  border-radius: 5vh;
}

.overF {
  overflow: auto;
  height: 40%;
  overflow-x: hidden;
}
.activeItem {
  cursor: default;
  display: flex;
  margin-left:3vh;
  padding-bottom:3vh;
}
.activeImage {
  width: 4.5vh;
  padding-right: 1.5vh;
}





::-webkit-scrollbar {
  width: 1.5vh;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background: #28142F; 
  border-radius: 10px;
  cursor: pointer;
}

